<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="6">
        <el-date-picker
          v-model="dateRange"
          value-format="x"
          type="datetimerange"
          @change="changeDate"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="手机号码"
          v-model="query.phone"
          @change="getList(1)"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-select placeholder="提现状态" v-model="query.status" clearable>
          <el-option
            v-for="(item, index) in this.const.USER_WITHDRAW"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>

        <el-button type="primary" @click="doExport">导出提现记录</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="姓名" prop="userRealName"></el-table-column>
      <el-table-column label="手机号" prop="phone"></el-table-column>
      <el-table-column label="金额" prop="amount">
        <template #default="scope">
          <span>{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="微信批次单号" prop="batchId"> </el-table-column>

      <el-table-column label="商户批次单号" prop="outBatchNo"></el-table-column>
      <el-table-column label="提现状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.USER_WITHDRAW"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="申请时间" prop="createTime">
        <template #default="scope">
          {{ this.$filter.dateFormat(new Date(scope.row.createTime)) }}
        </template>
      </el-table-column>

      <el-table-column label="操作" prop="operation">
        <template #default="scope">
          <span
            v-if="this.const.USER_WITHDRAW_CODE.WIDTH_AUDIT == scope.row.status"
            class="option option-primary"
            @click="doAudit(scope.row, this.const.USER_WITHDRAW_CODE.WAIT)"
            >通过提现</span
          >

          <span
            v-if="this.const.USER_WITHDRAW_CODE.WIDTH_AUDIT == scope.row.status"
            class="option option-danger"
            @click="
              doAudit(scope.row, this.const.USER_WITHDRAW_CODE.AUDIT_FAIL)
            "
            >驳回提现</span
          >

          <span
            v-if="this.const.USER_WITHDRAW_CODE.WAIT == scope.row.status"
            class="option option-primary"
            @click="getResult(scope.row)"
            >结果查询</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>


<script>
import {
  fetchUserAssetWithdraw,
  updateUserWithdrawResult,
  exportUserWithdrawList,
  userWithdrawAudit,
} from "@/api/batteryMan";

import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),

  data() {
    let startTime = moment()
      .startOf("day")
      .subtract(1, "month")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    return {
      dateRange: [startTime, endTime],

      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      endTime: -1,
      expire: -1,
      query: {},
      total: 0,
    };
  },

  mounted() {
    let phone = this.$router.currentRoute._value.query.phone;
    if (phone) {
      this.query.phone = phone;
    }
    this.getList(1);
  },

  methods: {
    doAudit(row, status) {

      this.$confirm("提现审核", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {

        userWithdrawAudit({
          id: row.id,
          status,
        }).then((res) => {
          this.getList();
        });
        
      });
    },

    doExport() {
      if (!this.query.startTime) {
        this.$message.error("请选择时间段");
        return;
      }

      exportUserWithdrawList(this.query).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${this.query.startTime}-${this.query.endTime}提现数据.xlsx`
        );
      });
    },

    getResult(data) {
      updateUserWithdrawResult({ id: data.id }).then((res) => {
        this.getList();
      });
    },

    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(new Date(parseInt(e[0])))
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(new Date(parseInt(e[1])))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.dateRange = e;
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
      this.getList(1);
    },

    getList(current) {
      console.log(this.query);
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchUserAssetWithdraw({ ...this.query, ...this.pages }).then((res) => {
        let list = res.data.data.records || [];
        this.total = res.data.data.total;
        this.dataSource = list;
      });
    },
  },
};
</script>
